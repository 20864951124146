/*
|==========================================================================
| Initialize Foundation
|==========================================================================
| 
| This initializes Foundation.
|
|==========================================================================
|
*/

jQuery(document).foundation();