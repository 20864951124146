/*
|==========================================================================
| Items in Viewport
|==========================================================================
| 
| This function detects if an item is in the viewport and could perhaps
| be improved upon.
|
|==========================================================================
| @reference: https://medium.com/talk-like/detecting-if-an-element-is-in-the-viewport-jquery-a6a4405a3ea2
|
*/

// Items in Viewport
function items_in_viewport(item_selector) {

  if(item_selector == ".outline_svg") {
    animationIn = 'inViewPort';
    animationOut = '';
  } 

  if(item_selector == ".grid-item .article-title") {
    animationIn = 'slideInUp';
    animationOut = 'slideOutDown';
  }

  if(jQuery(item_selector).length > 0) {

    if(item_selector === ".video-demo .html5vid iframe") {

      var iframe = jQuery(item_selector);
      var player = new Vimeo.Player(iframe);

      jQuery(item_selector+":above-the-top").each(function(index) {
        player.pause();
      });

      jQuery(item_selector+":in-viewport").each(function(index) {
        player.play();
      });

      jQuery(item_selector+":below-the-fold").each(function(index) {
        player.pause();
      });

    } else {

      jQuery(item_selector+":above-the-top").each(function(index) {
            // jQuery(this).removeClass(animationIn);
            // jQuery(this).addClass(animationOut);
          });

      jQuery(item_selector+":in-viewport").each(function(index) {
        jQuery(this).removeClass(animationOut);
        jQuery(this).addClass(animationIn);
      });

      jQuery(item_selector+":below-the-fold").each(function(index) {
            // jQuery(this).removeClass(animationIn);
            // jQuery(this).addClass(animationOut);
          });

    }

  }

}