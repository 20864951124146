/*
|==========================================================================
| Sticky Header
|==========================================================================
| 
| This function gives us the option to implement a sticky header, with
| custom options for class, height, and calculation.
|
|==========================================================================
|
*/

function sticky_header() {

    var window_height = jQuery(window).height();
    var element_height = 250;
    var element_title_height = jQuery(".title-homepage").height();
    var current_scroll_position = jQuery(window).scrollTop();
    var element_calc = (window_height - element_height) + 120;
    var element_calc_title = (window_height - element_title_height);

    //console.log('Element_calc: ' +element_calc);
    //console.log('Window_height: ' +window_height);
    //console.log(current_scroll_position);
    
    if (current_scroll_position >= element_calc) {
        jQuery('.header').addClass('sticky');
    } else {
        jQuery('.header').removeClass('sticky');
    }
}